import {
  Button,
  Dropdown,
  FullPageLoad,
  RowFlex,
  useAxios,
  useLoginContext,
  useUsers,
} from "common";
import { useState } from "react";
import { useParams } from "react-router-dom";
import CustomerBrandView, {
  InvoiceView,
  PoView,
  SoView,
} from "../CustomerBrandView";
import OrderView from "../OrderView";

const ViewCustomer = () => {
  const { isCrmAdmin } = useLoginContext();
  const users = useUsers();
  const { crmCustomerId } = useParams();
  const [salesRepId, setSalesRepId] = useState();
  const [inactive, setInactive] = useState(false); 
  const [viewOrders, setViewOrders] = useState(false);
  const { callAxios, loading } = useAxios();
  const url = `/crmCustomers/${crmCustomerId}`;
  
  const { response } = useAxios({
    callOnLoad: {
      method: "GET",
      url,
      params: {
        populate: JSON.stringify([
          { path: "latestSaleOrder", populate: "Brands" },
          "latestPurchaseOrder",
          "latestInvoice",
          "salesRep",
        ]),
      },
    },
    onComplete: (d) => {
      setSalesRepId(d.data?.salesRep?.id);
      setInactive(d.data?.inactive); 
    },
  });

  if (!response) return <FullPageLoad fillWidth />;

  const {
    latestSaleOrder,
    salesRep,
    latestInvoice,
    latestPurchaseOrder,
    CustomerName,
  } = response.data;

  const toggleCustomerStatus = () => {
    const updatedStatus = !inactive;
    setInactive(updatedStatus); 
    callAxios({
      method: "PUT",
      url: `/crmCustomers/${crmCustomerId}/inactive`,
      data: { inactive: updatedStatus }, 
    });
  };

  const stateElement = (
    <RowFlex responsive style={{ justifyContent: "flex-start", alignItems: "center" }}>
     
      <div style={{ width: "200px", marginRight: "10px" }}>
        {isCrmAdmin ? (
          <Dropdown
            label="Assign Sales Rep"
            options={users}
            disabled={loading}
            value={salesRepId}
            onChange={(v) => {
              setSalesRepId(v);
              callAxios({ method: "PUT", url, data: { salesRep: v } });
            }}
          />
        ) : (
          <div>Sales Rep: {salesRep?.name || "None"}</div>
        )}
      </div>

      <Button fit onClick={() => setViewOrders((p) => !p)}>
        View Orders
      </Button>
      <Button
        fit
        onClick={toggleCustomerStatus}
        disabled={loading}
        secondary={inactive}
        style={{ marginRight: "10px" }} 
      >
        {inactive ? "Activate Customer" : "Deactivate Customer"}
      </Button>

    </RowFlex>
  );

  if (viewOrders) return <OrderView stateElement={stateElement} />;

  return (
    <CustomerBrandView
      heading={
        <div>
          {CustomerName}
          <RowFlex style={{ fontSize: "0.8rem", fontWeight: "normal" }}>
            <SoView inline so={latestSaleOrder} />
            <PoView inline po={latestPurchaseOrder} />
            <InvoiceView inline invoice={latestInvoice} />
          </RowFlex>
        </div>
      }
      stateElement={
        <div>
          {stateElement} 
        </div>
      }
      isCustomer
    />
  );
};

export default ViewCustomer;
