import React, { useState } from "react";
import Select from "react-select";
import { Container, DownloadButton, DownloadLink, Viewer } from "./styles";
import CreateCustomer from "./CreateCustomer";
import { Alert } from "react-bootstrap";
import { baseURL, useAxios, useCrmCustomers, useExportFile } from "common";
import { OverflowContainer, RowFlex, TextStyledLink, DatePicker, Button,Spinner } from "common";

const ExcelView = ({ brand, params: p }) => {
  const { crmCustomers, loading, refetch, error } = useCrmCustomers();
  const [customers, setCustomers] = useState(p?.customers || []);
  const defaultFollowUpDate = new Date();
  defaultFollowUpDate.setDate(defaultFollowUpDate.getDate() + 7);

  const [followUpDate, setFollowUpDate] = useState(p?.followUpDate || defaultFollowUpDate);
  const [showCustomerModal, setShowCustomerModal] = useState(false);
  const [createCustomer, setCreateCustomer] = useState(false);

  const params = {
    ...p,
    customers: JSON.stringify(customers ?? []),
    followUpDate,
  };

  const { response } = useAxios({
    callOnLoad: { method: "GET", url: "/auth/me/token" },
  });
  const { getThisFile, exportLoading } = useExportFile(
    brand?.name || "BrandItems",
    `/brands/${brand.id}/download`
  );

  if (loading) return <Spinner />;

  if (error)
    return (
      <Alert variant="danger">
        Failed to load customers. Please try again.
      </Alert>
    );

  const payload = { token: response?.data, ...params };
  const url = `${baseURL}/brands/${brand.id}/download?payload=${JSON.stringify(payload)}`;
  const src = `https://view.officeapps.live.com/op/view.aspx?src=${url}`;

  const closeCustomerCreate = () => setCreateCustomer(false);

  const handleDownloadClick = () => {
    if (params.isRaw || customers.length > 0) {
      getThisFile(params);
    } else {
      setShowCustomerModal(true);
    }
  };

  const handleCustomerSelection = (selected) => {
    if (selected.some((option) => option.value === "select_all")) {
      const maxSelection = crmCustomers
        .filter((customer) => !customer.inactiveKey)
        .slice(0, 40)
        .map((customer) => customer.value);
      setCustomers(maxSelection);
    } else {
      setCustomers(selected.map((option) => option.value));
    }
  };

  const renderSelectedCustomers = () => {
    const displayedCustomers = crmCustomers
      .filter((c) => customers.includes(c.value) && !c.inactiveKey)
      .slice(0, 3);

    const remainingCount = customers.length - displayedCustomers.length;

    return (
      <div style={{ fontSize: "0.7rem" }}>
        <strong>Selected Customers: </strong>
        {displayedCustomers.map((c) => (
          <div key={c.value}>{c.label}</div>
        ))}
        {remainingCount > 0 && <div>and {remainingCount} more...</div>}
      </div>
    );
  };

  const customFilterOption = (option, searchText) => {
    const { label, emailKey } = option.data;
    if (!searchText) return true;
    return (
      label.toLowerCase().includes(searchText.toLowerCase()) ||
      (emailKey && emailKey.toLowerCase().includes(searchText.toLowerCase()))
    );
  };

  const customerOptions = crmCustomers
    .filter((c) => !c.inactiveKey)
    .map((c) => ({ label: c.label, value: c.value, emailKey: c.emailKey }));

  if (createCustomer)
    return (
      <OverflowContainer>
        <CreateCustomer
          cancel={closeCustomerCreate}
          onComplete={(customer) => {
            setCustomers((prevCustomers) => [...prevCustomers, customer]);
            refetch();
            closeCustomerCreate();
          }}
        />
      </OverflowContainer>
    );

  if (showCustomerModal)
    return (
      <OverflowContainer>
        <RowFlex>
          <div>
            <RowFlex>
              <Select
                isMulti
                options={[
                  { label: "Select All (Max 40)", value: "select_all" },
                  ...customerOptions,
                ]}
                value={customers.map((cust) =>
                  customerOptions.find((opt) => opt.value === cust)
                )}
                onChange={handleCustomerSelection}
                closeMenuOnSelect={false}
                placeholder="Select Customers"
                isSearchable={true}
                filterOption={customFilterOption} 
              />
              <TextStyledLink
                style={{ fontSize: "0.7rem", marginLeft: "10px" }}
                onClick={() => setCreateCustomer(true)}
              >
                Or Create New Customer
              </TextStyledLink>
            </RowFlex>
            <br />
            <RowFlex style={{ alignItems: "center" }}>
              <DatePicker
                label="Follow Up Date (Optional)"
                value={followUpDate}
                onChange={(d) => setFollowUpDate(d)}
              />
              <Button
                style={{
                  marginLeft: "15px",
                  height: "32px",
                  fontSize: "0.75rem",
                  padding: "0 10px",
                  marginTop: "50px",
                }}
                onClick={() => setFollowUpDate(null)}
                secondary
              >
                Reset
              </Button>
            </RowFlex>
            <br />
          </div>
        </RowFlex>
        <Button
          fit
          disabled={customers.length === 0}
          secondary
          onClick={() => {
            setShowCustomerModal(false);
            getThisFile(params);
          }}
        >
          Proceed
        </Button>
      </OverflowContainer>
    );

  return (
    <Container>
      <RowFlex>
        {customers.length > 0 && renderSelectedCustomers()}
        <DownloadButton loading={exportLoading} onClick={handleDownloadClick}>
          Download
        </DownloadButton>
        <DownloadLink
          to="#"
          onClick={() => getThisFile({ ...params, skipSalonPrice: true })}
        >
          Download without Salon Price
        </DownloadLink>
      </RowFlex>
      <Viewer src={encodeURI(src)} style={{ marginTop: "20px" }} />
    </Container>
  );
};

export default ExcelView;
