import React, { useState } from "react";
import {
  formatNumber,
  linkPlaceholders,
  routing,
  TableView,
  useBrands,
  useLoginContext,
  useUsers,
} from "common";
import { InvoiceView, PoView, SoView } from "../CustomerBrandView";
import { differenceInMonths } from "date-fns";
import "./CustomerList.css";
import CreateCustomer from "../../Brands/ViewBrand/ExcelView/CreateCustomer"; 

const CustomerList = () => {
  const { brands } = useBrands();
  const users = useUsers();
  const { isCrmAdmin } = useLoginContext();

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => setIsModalVisible(true);

  const hideModal = () => setIsModalVisible(false);

  
  const handleCustomerCreation = (customerId) => {
    console.log("Customer created with ID: ", customerId);
    hideModal(); 
  };

  return (
    <div>

    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
      <button
        className="btn btn-primary add-customer-button"
        onClick={showModal}
        style={{
          padding: '10px 15px',
          fontSize: '16px',
          fontWeight: 'bold',
          backgroundColor: '#FF9696', 
          border: 'none',
          borderRadius: '5px',
          color: '#fff',
          cursor: 'pointer',
          transition: 'background-color 0.3s ease',
        }}
        onMouseEnter={(e) => (e.target.style.backgroundColor = '#FF9696')} 
        onMouseLeave={(e) => (e.target.style.backgroundColor = '#FF7070')} 
      >
        Add Customer
      </button>
    </div>
  


     
      {isModalVisible && (
        <div className="modal-overlay">
          <div className="modal-container">
            <CreateCustomer cancel={hideModal} onComplete={handleCustomerCreation} />
          </div>
        </div>
      )}
      <TableView
        url="/crmCustomers"
        tableConfig={[
          { name: "CustomerName", header: "Name" },
          { name: "salesRep", header: "Sales Rep" },
          { name: "brandLength", header: "Brands" },
          { name: "potentialBrandLength", header: "Potential Brands" },
          { name: "latestSaleOrder", header: "Latest SO" },
          { name: "latestPurchaseOrder", header: "Latest PO" },
          { name: "latestInvoice", header: "Latest Invoice" },
          { name: "Email", header: "Email" },
          { name: "invoiceCount", header: "Invoices" },
          { name: "invoiceTotalForPastYear", header: "12 Months" },
        ]}
        defaultParams={{
          populate: JSON.stringify([
            { path: "salesRep" },
            { path: "latestSaleOrder" },
            { path: "latestPurchaseOrder" },
            { path: "latestInvoice" },
            { path: "invoices", select: "TotalAmount PaidAmount CreateDate" },
          ]),
        }}
        to={routing.crm.viewCustomer}
        linkParam={linkPlaceholders.crmCustomerId}
        header="Customers"
        filterConfig={[
          { name: "CustomerName", label: "Name", type: "input" },
          { name: "Email", label: "Email", type: "input" },
          { name: "brands", label: "Brand", type: "dropdown", options: brands },
          {
            name: "potentialBrands",
            label: "Potential Brand",
            type: "dropdown",
            options: brands,
          },
          ...(isCrmAdmin
            ? [{ name: "salesRep", label: "Sales Rep", type: "dropdown", options: users }]
            : []),
        ]}
        shapeData={(d) =>
          d.data.data.map((cust) => {
            let [invoiceCount, TotalAmount, PaidAmount, outstanding] = [0, 0, 0, 0];
            for (const invoice of cust.invoices) {
              invoiceCount++;
              if (differenceInMonths(new Date(), new Date(invoice.CreateDate)) <= 12) {
                TotalAmount += invoice.TotalAmount;
                PaidAmount += invoice.PaidAmount;
                outstanding += invoice.outstandingAmount;
              }
            }
            return {
              ...cust,
              invoiceCount,
              brandLength: cust.brands.length,
              potentialBrandLength: cust.potentialBrands.length,
              salesRep: cust.salesRep?.name,
              latestSaleOrder: <SoView so={cust.latestSaleOrder} inline />,
              latestPurchaseOrder: <PoView po={cust.latestPurchaseOrder} inline />,
              latestInvoice: <InvoiceView invoice={cust.latestInvoice} inline />,
              invoiceTotalForPastYear:
                invoiceCount === 0 ? (
                  ""
                ) : (
                  <div>
                    <div>
                      <strong>Amount</strong>: ${formatNumber(TotalAmount.toFixed())}
                    </div>
                    <div>
                      <strong>Paid</strong>: ${formatNumber(PaidAmount.toFixed())}
                    </div>
                    <div>
                      <strong>Outstanding</strong>: ${formatNumber(outstanding.toFixed())}
                    </div>
                  </div>
                ),
            };
          })
        }
      />
    </div>
  );
};

export default CustomerList;
